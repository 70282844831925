@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.eot');
    src:
        url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Ultra/UniviaPro-Ultra.svg#UniviaPro-Ultra') format('svg');
    font-weight: 950;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.eot');
    src:
        url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Black/UniviaPro-Black.svg#UniviaPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.eot');
    src:
        url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Bold/UniviaPro-Bold.svg#UniviaPro-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.eot');
    src:
        url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Medium/UniviaPro-Medium.svg#UniviaPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.eot');
    src:
        url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Regular/UniviaPro-Regular.svg#UniviaPro-Regular')
            format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.eot');
    src:
        url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Book/UniviaPro-Book.svg#UniviaPro-Book') format('svg');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.eot');
    src:
        url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Light/UniviaPro-Light.svg#UniviaPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.eot');
    src:
        url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.woff') format('woff'),
        url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/UltraLight/UniviaPro-UltraLight.svg#UniviaPro-UltraLight')
            format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univia Pro';
    src: url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.eot');
    src:
        url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.woff2') format('woff2'),
        url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.woff') format('woff'),
        url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.ttf') format('truetype'),
        url('../assets/fonts/UniviaPro/Thin/UniviaPro-Thin.svg#UniviaPro-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/Icomoon/icomoon.eot?qq6rj1');
    src:
        url('../assets/fonts/Icomoon/icomoon.eot') format('embedded-opentype'),
        url('../assets/fonts/Icomoon/icomoon.ttf') format('truetype'),
        url('../assets/fonts/Icomoon/icomoon.woff') format('woff'),
        url('../assets/fonts/Icomoon/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
